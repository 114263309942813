// 微信展码链接参数
export const WX_PARAMS = {
  appid: 'wx681b4630b4ade9af',
  redirectUri: encodeURIComponent('https://webauth.wecity.qq.com'),
  scope: "snsapi_login",
  styleHref: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=='
};

// QQ登录链接参数
export const QQ_PARAMS = {
  appid: '100556005',
  redirectUri: encodeURIComponent('http://www.corho.com:8080/#/login/callback')
};
