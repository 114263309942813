<!--  -->
<template>
  <div class="wx-box"  @click="wxClick">
      <img slot="item-icon" src="~assets/img/wx.svg" alt="" />
      <span slot="item-text">微信快捷登录</span>
  </div>
</template>

<script>
export default {
  name: 'WxLogin',
  methods: {
    wxClick() {
      this.$emit("chooseWx", false);
    }
  }
};
</script>

<style  scoped>
.wx-box {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 -1px 1px rgba(100, 100, 100, .2);
}

.wx-box img {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}
</style>
