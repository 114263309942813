<!--  -->
<template>
  <div class="login-header">
    <img src="~assets/img/login-back.png" alt="" @click="backClick" />
    <span>微信登录</span>
    <div id="login_container" class="login_container"></div>
    <div>打开微信扫一扫，请在手机上确认登录</div>
  </div>
</template>

<script>
import { WX_PARAMS } from 'constants/Login';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'WxQrcode',
  mounted() {
    this.getWxQrcode();   // 获取微信的登录二维码
  },
  computed: {
    iframeRedirect() {
      return `https://webauth.wecity.qq.com/redirect.html?redirect_url=${encodeURIComponent(this.$route.query.redirect_url)}`;
    }
  },
  methods: {
    backClick() {
      this.$emit('backLogin', true);
    },
    // 微信展码
    getWxQrcode() {
      const redirectUri = encodeURIComponent(`https://webauth.wecity.qq.com/api/login_wechat?type=wechat&appid=${WX_PARAMS.appid}&redirect_url=${encodeURIComponent(this.iframeRedirect)}&service=${this.$route.query.service}&`);
      const obj = new window.WxLogin({
        self_redirect: true,
        id: 'login_container',
        appid: WX_PARAMS.appid,
        scope: WX_PARAMS.scope,
        redirect_uri: redirectUri, // 回调域名
        state: uuidv4(),
        style: 'black',
        href: WX_PARAMS.styleHref  // 微信二维码样式
      });
    }
  }
};
</script>

<style  scoped>
.login-header {
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #787d82;
  margin-top: 5px;
}

.login-header img {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.login-header span {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.login_container {
  height: 230px;
}
</style>
