<!--  -->
<template>
  <div>
    <div class="login-header">
      <h1><span>验证码登录</span></h1>
    </div>
    <div class="login-body">
      <form id="signup-form">
        <div class="phone-num pr">
          <div class="phone-areacode">+86</div>
          <input
            type="text"
            maxlength="11"
            name="phone"
            class="ipt ipt-phone"
            placeholder="请输入手机号"
            autocomplete="off"
            @blur="phoneTipShow()"
            v-model="form.phoneNum"
          />
          <div class="err-tip">
            <p v-if="phoneError">请输入正确手机号</p>
          </div>
        </div>
        <div class="phone-verify pr">
          <input
            type="text "
            maxlength="6"
            class="ipt ipt-pwd"
            placeholder="请输入短信验证码"
            autocomplete="off"
            @blur="codeTipShow()"
            v-model="form.smsCode"
          />
          <div class="err-tip">
            <p v-if="smsCodeError">请输入正确验证码</p>
          </div>
          <div
            class="sms-code"
            :class="{ disabled: isDisabled }"
            @click="handlerCode"
            id="TencentCaptcha"
            data-appid="2004797314"
            data-cbfn="callback"
          >
            {{ codeText }}
          </div>
        </div>
      </form>
      <input
        type="button"
        value="登录"
        hidefocus="true"
        class="login-btn"
        @click="handlerLogin"
      />
    </div>
    <!-- 分割线 -->
    <div class="divid-line"></div>
  </div>
</template>

<script>
export default {
  name: 'PhoneLogin',
  data() {
    return {
      phoneError: false,
      smsCodeError: false,
      isDisabled: false,
      codeText: '获取验证码',
      timer: null,
      form: {
        phoneNum: '',
        smsCode: ''
      },
    };
  },
  async created() {
    // 带上参数appid
    if (this.$route.query.appId === undefined) {
      const { service } = this.$route.query;
      const { appId }  = await this.$api('/api/get_service_info', { serviceName: service });
      this.$router.replace({query: {
        ...this.$route.query,
        appId: appId
      }});
      window.location.replace(window.location.href);
    } else if (this.$route.query.appId !== undefined && this.$route.query.code === '10014') {  // 微信登录但未绑定手机号
      alert('您未绑定手机号，请使用手机验证码重新进行登录！');
      // this.$toast.showMessage('您未绑定手机号，请使用手机验证码重新进行登录！', 'warn', 5 * 1000);
    }
  },
  methods: {
    // 手机号错误提示
    phoneTipShow() {
      if (this.form.phoneNum.length < 11) {
        this.phoneError = true;
      }
    },
    // 验证码错误提示
    codeTipShow() {
      if (this.form.smsCode.length < 6) {
        this.smsCodeError = true;
      }
    },
    // 获取短信验证码
    handlerCode() {
      if (this.form.phoneNum.length < 11) {
        this.phoneError = true;
      } else {
        // 滑块验证
        const captcha = new window.TencentCaptcha('2004797314', res => {
          const that = this;
          if (res.ret === 0) {
            that.countDown();
            that.$api("/api/verify_code", {
              request: {
                verify_code_req: {
                  phone_number: this.form.phoneNum,
                  ticket: res.ticket,
                  randstr: res.randstr
                }
              }
            });
          } else {
            // 用户主动关闭验证码
            if (res.ret === 2) {
              that.$toast.showMessage('未完成滑块验证', 'warn');
              return;
            } else {
              alert("sth wrong");
            }
          }
        });
        captcha.show();
      }
    },
    // 倒计时函数
    countDown() {
      let second = 60;
      this.codeText = `${second}秒 后可重发`; // 初始化文本
      this.isDisabled = true; // 禁用按钮
      // 倒计时
      this.timer = setInterval(() => {
        second -= 1; // 累减
        this.codeText = `${second}秒 后可重发`; // 更新文本
        if (second === 0) {
          // 倒计时为0时，清除定时器
          this.codeText = `重新获取`;
          this.isDisabled = false; // 激活按钮
          // 清除定时器
          clearInterval(this.timer);
        }
      }, 1000);
    },
    // 手机登录
    async handlerLogin() {
      if (this.form.phoneNum.length === 11 && this.form.smsCode.length === 6) {
        try {
          const res = await this.$api("/api/login", {
            request: {
              login_req: {
                type: 'phone',
                code: this.form.smsCode,
                phone_number: this.form.phoneNum,
                service: this.$route.query.service,
                redirect_url: this.$route.query.redirect_url
              }
            },
          });
          console.log(res);
          if (res.code === 0) {
            this.$toast.showMessage('登录成功！', 'success');
            window.location.replace(res.redirectUrl);
          } else {
            this.$toast.showMessage(`${res.message}`, 'error', 3000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  }
};
</script>

<style  scoped>
.login-header h1 {
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #787d82;
}

.login-header h1 span {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.login-body {
  padding: 0 32px;
  margin-bottom: 20px;
}

.phone-areacode {
  position: absolute;
  cursor: pointer;
  left: 16px;
  top: 12px;
  font-size: 16px;
  color: #1c1f21;
  text-align: left;
  line-height: 24px;
}

.ipt {
  width: 100%;
  height: 48px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px;
  box-shadow: 2px 0 5px 0 #fff;
  background: rgba(28, 31, 33, .06);
  border-radius: 8px;
  border: 0;
  outline: none;
}

.ipt-phone {
  padding-left: 57px;
}

.sms-code {
  position: absolute;
  cursor: pointer;
  right: 14px;
  top: 12px;
  font-size: 14px;
  color: #1481b8;
  line-height: 24px;
}

.disabled {
  color: #8590a6;
  opacity: .5;
  pointer-events: none;
}

.login-btn {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 32px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  background-color: #2083f6;
  border-color: #2083f6;
  color: #fff;
  opacity: 1;
}
</style>
