<template>
  <div class="login-wrap">
    <div class="login-box">
      <!-- 手机验证码登录 -->
      <div v-if="isPhoneLogin">
        <phone-login />
        <wx-login  @chooseWx="chooseWx" />
      </div>
      <!-- 微信扫码登录 -->
      <wx-qrcode v-else  @backLogin="backLogin"/>
    </div>
  </div>
</template>

<script>
import WxLogin from './WxLogin.vue';
import PhoneLogin from './PhoneLogin.vue';
import WxQrcode from './WxQrcode.vue';

export default {
  name: 'Login',
  components: { WxLogin, PhoneLogin, WxQrcode },
  data() {
    return {
      isPhoneLogin: true
    };
  },
  methods: {
    chooseWx(type) {
      this.isPhoneLogin = type;
    },
    backLogin(type) {
      this.isPhoneLogin = type;
    }
  }
};
</script>


<style  scoped>
.login-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-box {
  background: #fff;
  width: 384px;
  height: 330px;
  box-shadow: 0 12px 24px 0 rgb(28 31 33 / 10%);
  border-radius: 12px;
  position: relative;
}
</style>
